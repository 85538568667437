<template>
  <div class="pricing-body">
    <!--packages-->
    <div class="enable3Packages-body" v-if="categorySubId !== 61">
      <p class="enable3Packages">3’lü Paket</p>
      <custom-switch v-model="enable3Packages" />
    </div>
    <div class="table-container" ref="mainPricingTableId" v-if="categorySubId !== 61">
      <div v-if="!enable3Packages" class="packagesOpacity">
        <div style=" width:80%; padding-top:120px;margin: 0 auto; text-align: center">
          <p style="color: #2d3640; font-size: 18px; font-weight: 600; text-align: center">3'lü paket deneyerek, ilanına daha fazla seçenek ekle.</p>
          <button @click="enable3Packages= true" style="margin: 0 auto; margin-top: 20px; border-radius: 4px; font-weight: 600; min-width: 130px; text-align: center" class="super-button-green">Şimdi Dene!</button>
        </div>
      </div>

      <div class="table-item-body should-left">
        <p scope="col" class="table-col-header"><span style="padding-left: 16px">TEMEL</span></p>
        <p scope="col" class="table-col-header"><span style="padding-left: 16px">STANDART</span></p>
        <p scope="col" class="table-col-header"><span style="padding-left: 16px">PRO</span></p>
      </div>

      <!--static-->
      <div class="table-item-body should-left">
        <div class="table-item">
          <textarea
                  maxlength="36"
            class="pricing-input"
            type="text"
            v-model="basic.selected_title"
            placeholder="Paketi isimlendir">
          </textarea>
        </div>
        <div class="table-item">
          <textarea
                  maxlength="36"
            class="pricing-input"
            type="text"
            v-model="standard.selected_title"
            placeholder="Paketi isimlendir">
          </textarea>
        </div>
        <div class="table-item">
           <textarea
                   maxlength="36"
             class="pricing-input"
             type="text"
             v-model="premium.selected_title"
             placeholder="Paketi isimlendir">
          </textarea>
        </div>
        <aside class="tooltiptext">
          <help-box header="Başlık" title="" description="<ul style='margin-left:20px;list-style:disc'><li>Paketlerine içeriklerini özetleyen etkileyici bir başlık seç</li><li style='margin-top:20px'>Örneğin; 2D veya 3D kaplama, Baskıya Hazır Versiyon gibi</li></ul>"></help-box>
        </aside>
      </div>
      <div class="table-item-body should-left">
        <div class="table-item">
          <textarea
                  maxlength="105"
            class="pricing-input"
            type="text"
            v-model="basic.selected_description"
            style="height: 100px;"
            placeholder="Nasıl bir hizmet sunduğunu detaylandır.">
          </textarea>

        </div>
        <div class="table-item">
          <textarea
                  maxlength="105"
            class="pricing-input"
            type="text"
            v-model="standard.selected_description"
            style="height: 100px;"
            placeholder="Nasıl bir hizmet sunduğunu detaylandır.">
          </textarea>

        </div>
        <div class="table-item">
          <textarea
                  maxlength="105"
            class="pricing-input"
            type="text"
            v-model="premium.selected_description"
            style="height: 100px;"
            placeholder="Nasıl bir hizmet sunduğunu detaylandır.">
          </textarea>

        </div>

        <aside class="tooltiptext">
          <help-box header="Paket açıklaması" title="" description="<ul style='margin-left:20px;list-style:disc'><li>Bu paketin alıcılara hangi hizmeti sunduğunu ve bu hizmetleri neden bu pakete eklediğin özetle</li><li style='margin-top:20px'>Bu alanda maksimum 100 karakter kullanabilirsin</li></ul><p style='margin-top:24px'><strong>Örnek:</strong> Bu paket standart logo tasarımı ile birlikte, 4 revize ve kaynak dosyalarını içerir</p>"></help-box>
        </aside>
      </div>
      <div class="table-item-body should-left">
        <div class="table-item">
          <select v-model="basic.selected_duration"
                  class="pricing-dropdown">
            <option :value="0">Teslim Süresi</option>
            <option v-for="i in 90" :value="i">{{i}} gün içinde teslim</option>
          </select>
        </div>
        <div class="table-item">
          <select v-model="standard.selected_duration"
                  class="pricing-dropdown">
            <option :value="0">Teslim Süresi</option>
            <option v-for="i in 90" :value="i">{{i}} gün içinde teslim</option>
          </select>
        </div>
        <div class="table-item">
          <select v-model="premium.selected_duration"
                  class="pricing-dropdown">
            <option :value="0">Teslim Süresi</option>
            <option v-for="i in 90" :value="i">{{i}} gün içinde teslim</option>
          </select>
        </div>
        <aside class="tooltiptext">
          <help-box header="Teslimat Süresi" title="" description="<ul style='margin-left:20px;list-style:disc'><li>Teslim süresi, alıcılar paketi satın aldıkları anda başlayan pakette belirlediğin tüm hizmetleri tamamlamak için ihtiyaç duyduğun süredir.</li><li style='margin-top:20px'>Pakette belirtilen her hizmeti zamanında tamamlayabileceğin gerçekçi teslimat süreleri belirle.</li></ul>"></help-box>
        </aside>
      </div>

      <div class="table-item-body should-left">
        <div class="table-item">
          <select v-model="basic.selected_revisions"
                  class="pricing-dropdown">
            <option :value="null">Revizyon Sayısı</option>
            <option :value="0">0 Revizyon</option>
            <option v-for="i in 1000" :value="i">{{i}} revizyon</option>
          </select>
        </div>
        <div class="table-item">
          <select v-model="standard.selected_revisions"
                  class="pricing-dropdown">
            <option :value="null">Revizyon Sayısı</option>
            <option :value="0">0 Revizyon</option>
            <option v-for="i in 10" :value="i">{{i}} revizyon</option>
          </select>
        </div>
        <div class="table-item">
          <select v-model="premium.selected_revisions"
                  class="pricing-dropdown">
            <option :value="null">Revizyon Sayısı</option>
            <option :value="0">0 Revizyon</option>
            <option v-for="i in 10" :value="i">{{i}} revizyon</option>
          </select>
        </div>
        <aside class="tooltiptext">
          <help-box header="Revizyon Bilgisi" title="" description="<ul style='margin-left:20px;list-style:disc'><li>Sipariş süreçlerinde revize bilgileri genel olarak sipariş sonundaki düzeltmeler anlamına gelir.</li><li style='margin-top:20px'>Paketlerine dahil etmek istediğin revizyon sayılarını belirle</li></ul>"></help-box>
        </aside>
      </div>

      <!--dynamic-->
      <div class="table-item-body" v-for="(item, index) in gigPackageItems" :key="item.gig_package_item_id" @mouseover="setTooltipData(item)">
        <p scope="row" class="table-row-header"><span class="span">{{item.title}}</span></p>

        <!--basic-->
        <div class="table-item">
          <input class="pricing-input"
                 type="number"
                 v-model="basic['selected_' + item.gig_package_item_id]"
                 :placeholder="item.title"
                 v-if="item.type === 'input'"/>
          <div class="checkbox-body" v-if="item.type === 'checkbox'">
            <div class="checkbox-box"
                 @click="handleCheckBoxData(!basic['selected_' + item.gig_package_item_id],item.gig_package_item_id, 1, item)">
              <img v-if="basic['selected_' + item.gig_package_item_id]" class="checkbox-img"
                   :src="basic['selected_' + item.gig_package_item_id] ? 'https://gcdn.bionluk.com/site/icon/tansel_radio_tick_greed.svg' : ''"/>
            </div>
          </div>

          <select v-model="basic['selected_' + item.gig_package_item_id]"
                  class="pricing-dropdown" v-if="item.type === 'selectbox'" @change="handleSelectBoxData(basic['selected_' + item.gig_package_item_id] ,item)">
            <option :value="null">Seçin...</option>
            <template v-if="item.info && item.info.selectboxRangeStart && item.info.selectboxRangeEnd">
              <option  v-for="i in (item.info.selectboxRangeEnd/item.info.selectboxRangeInterval)" :value="i * item.info.selectboxRangeInterval">{{i * item.info.selectboxRangeInterval }}{{item.info.selectboxHelpText}}</option>
            </template>
            <option v-else v-for="i in 20" :value="i">{{i}}</option>
          </select>
        </div>

        <!--standard-->
        <div class="table-item">
          <input class="pricing-input"
                 type="number"
                 v-model="standard['selected_' + item.gig_package_item_id]"
                 :placeholder="item.title"
                 v-if="item.type === 'input'"/>
          <div class="checkbox-body" v-if="item.type === 'checkbox'">

            <div class="checkbox-box"

                 @click="handleCheckBoxData(!standard['selected_' + item.gig_package_item_id],item.gig_package_item_id, 2, item)">
              <img v-if="standard['selected_' + item.gig_package_item_id]" class="checkbox-img"
                   :src="standard['selected_' + item.gig_package_item_id] ? 'https://gcdn.bionluk.com/site/icon/tansel_radio_tick_greed.svg' : ''"/>
            </div>
          </div>
          <select v-model="standard['selected_' + item.gig_package_item_id]"
                  class="pricing-dropdown" v-if="item.type === 'selectbox'" @change="handleSelectBoxData(standard['selected_' + item.gig_package_item_id], item)">
            <option :value="null">Seçin ...</option>
            <template v-if="item.info && item.info.selectboxRangeStart && item.info.selectboxRangeEnd">
              <option  v-for="i in (item.info.selectboxRangeEnd/item.info.selectboxRangeInterval)" :value="i * item.info.selectboxRangeInterval">{{i * item.info.selectboxRangeInterval }}{{item.info.selectboxHelpText}}</option>
            </template>
            <option v-else v-for="i in 20" :value="i">{{i}}</option>
          </select>

        </div>

        <!--premium-->
        <div class="table-item">
          <input class="pricing-input"
                 type="number"
                 v-model="premium['selected_' + item.gig_package_item_id]"
                 :placeholder="item.title"
                 v-if="item.type === 'input'"/>
          <div class="checkbox-body" v-if="item.type === 'checkbox'">

            <div class="checkbox-box"
                 @click="handleCheckBoxData(!premium['selected_' + item.gig_package_item_id],item.gig_package_item_id, 3, item)">
              <img v-if="premium['selected_' + item.gig_package_item_id]" class="checkbox-img"
                   :src="premium['selected_' + item.gig_package_item_id] ? 'https://gcdn.bionluk.com/site/icon/tansel_radio_tick_greed.svg' : ''"/>
            </div>
          </div>
          <select v-model="premium['selected_' + item.gig_package_item_id]"
                  class="pricing-dropdown" v-if="item.type === 'selectbox'" @change="handleSelectBoxData(premium['selected_' + item.gig_package_item_id], item)">
            <option :value="null">Seçin ...</option>
            <template v-if="item.info && item.info.selectboxRangeStart && item.info.selectboxRangeEnd">
              <option  v-for="i in (item.info.selectboxRangeEnd/item.info.selectboxRangeInterval)" :value="i * item.info.selectboxRangeInterval">{{i * item.info.selectboxRangeInterval }}{{item.info.selectboxHelpText}}</option>
            </template>
            <option v-else v-for="i in 20" :value="i">{{i}}</option>
          </select>
        </div>
        <aside class="tooltiptext">
          <help-box header="Pakete içeriğine eklemek için seçim yapın" :title="item.title" :description="tooltipData.description"></help-box>
        </aside>
      </div>

      <!--pricing-->
      <div class="table-item-body">
        <p scope="row" class="table-row-header" style="border-bottom: none">
          <span class="span">Paket Fiyatı</span>
        </p>
        <div class="table-item" style=" border-bottom:none;">
          <select v-model="basic.selected_pricing"
                  class="pricing-dropdown">
            <option :value="0">Seçin ...</option>
            <option v-for="i in 100" :value="i*10" v-if="i*10 > 99">{{i*10}} TL</option>
            <option v-for="i in 90" :value="1000 + (i*100)" >{{1000 + (i*100)}} TL</option>
            <option v-for="i in 80" :value="10000 + (i*500)">{{10000 + (i*500)}} TL</option>
          </select>
        </div>
        <div class="table-item" style=" border-bottom:none;">
          <select v-model="standard.selected_pricing"
                  class="pricing-dropdown">
            <option :value="0">Seçin ...</option>
            <option v-for="i in 100" :value="i*10" v-if="i*10 > 99">{{i*10}} TL</option>
            <option v-for="i in 90" :value="1000 + (i*100)">{{1000 + (i*100)}} TL</option>
            <option v-for="i in 80" :value="10000 + (i*500)">{{10000 + (i*500)}} TL</option>
          </select>
        </div>
        <div class="table-item" style=" border-bottom:none;">
          <select v-model="premium.selected_pricing"
                  class="pricing-dropdown">
            <option :value="0">Seçin ...</option>
            <option v-for="i in 100" :value="i*10" v-if="i*10 > 99">{{i*10}} TL</option>
            <option v-for="i in 90" :value="1000 + (i*100)">{{1000 + (i*100)}} TL</option>
            <option v-for="i in 80" :value="10000 + (i*500)">{{10000 + (i*500)}} TL</option>
          </select>
        </div>
        <aside class="tooltiptext">
          <help-box header="Fiyatlandırma" title="" description="<ul style='margin-left:20px;list-style:disc'><li>İş ilanın için farklı hizmetler ve fiyatlar içeren paketler oluşturarak daha fazla kazanma şansı yakala!</li><li style='margin-top:20px'>Paket fiyatlarını en ucuzdan pahalıya doğru sırala. (Örneğin; Temel'den Pro'ya)</li><li style='margin-top:20px'>İstediğin zaman paket fiyatlarını güncelleyebilirsin</li><li style='margin-top:20px'>Bu alanda belirteceğin paketler için temel fiyatlandırmalar olacaktır. Ekstra seçenekler için biraz alt taraftaki alandan ek seçimler yapabilirsin.</li></ul>"></help-box>
        </aside>
      </div>
    </div>

    <!--subCategory = voiceover-->

    <div class="table-container voiceover" v-if="categorySubId === 61">
      <div class="voiceover-item-container">
        <p class="voiceover-label">Paket İçindeki Kelime Sayısı</p>
        <input type="number" v-model="voiceoverOptions.includes" class="small-input" placeholder="kelime sayısı">
        <p class="input-description-right">kelime</p>
      </div>
      <div class="voiceover-item-container">
        <p class="voiceover-label">Revizyon Sayısı</p>
        <select  v-model="voiceoverOptions.revisions" class="extras-dropdown">
          <option :value="null">Seç</option>
          <option value="0">0</option>
          <option v-for="i in 10" :value="i">{{i}} adet</option>
        </select>
        <p class="input-description-right"></p>
      </div>
      <div class="voiceover-item-container">
        <p class="voiceover-label">Fiyat</p>
        <select  v-model="voiceoverOptions.price" class="extras-dropdown">
          <option :value="null">Fiyat</option>
          <option v-for="i in 1000" :value="i*10" v-if="i*10 > 99">{{i*10}} TL</option>
        </select>
      </div>
      <div class="voiceover-seperator"></div>
      <div class="voiceover-item-container">
        <p class="voiceover-label">Ekstra</p>
        <p class="input-description-left">Ekstra her</p>
        <input type="number" v-model="voiceoverOptions.additionalWordCount" class="small-input" placeholder="kelime sayısı">
        <p class="input-description-center">kelime için</p>
        <select  v-model="voiceoverOptions.additionalWordPrice" class="extras-dropdown">
          <option :value="null">Fiyat</option>
          <option v-for="i in 1000" :value="i*10">+{{i*10}} TL</option>
        </select>
      </div>
      <div class="voiceover-seperator"></div>
      <div class="voiceover-item-container">
        <p class="voiceover-label">Teslim Süresi</p>
        <select  v-model="voiceoverOptions.duration" class="extras-dropdown">
          <option :value="null">Teslim Süresi</option>
          <option v-for="i in 90" :value="i">{{i}} gün</option>
        </select>
      </div>
    </div>

    <!--gig extras-->
    <div class="hiddendiv"></div>
    <p class="service-type-title" style="padding-left: 25px">İş İlanı Ekstraları</p>
    <div class="table-container" style="margin-top: 25px;">

      <!--extra fast delivery-->
      <div class="table-item-body extras">
        <div class="extras-header">
          <div class="checkbox-box"
               @click="extraFastDelivery.selected = !extraFastDelivery.selected">
            <img v-if="extraFastDelivery.selected" class="checkbox-img"
                 :src="extraFastDelivery.selected ? 'https://gcdn.bionluk.com/site/icon/tansel_radio_tick_greed.svg' : ''"/>
          </div>
          <p class="extras-title">Süper Hızlı Teslimat</p>
        </div>
        <aside class="tooltiptext">
          <help-box header="Süper Hızlı Teslimat" title="" description="<ul style='margin-left:20px;list-style:disc'><li>Daha hızlı teslim süresi için fiyatlandırmanı oluştur.</li><li style='margin-top:20px'>Süper Hızlı Teslimat süresi, paketlerinde belirttiğin süreden daha kısa olmalıdır.</li></ul>"></help-box>
        </aside>
      </div>
      <!--extras fast delivery body -->
      <div class="table-item-body extras" style="flex-direction: column; align-items: flex-start" v-if="extraFastDelivery.selected">
        <div class="extras-fast-inner">
          <p class="custom-extras-title">TEMEL PAKET</p>
          <div class="extras-additional">
            <p class="input-description-left">Sadece</p>
            <select v-model="extraFastDelivery.basic.duration"
                    class="extras-dropdown">
              <option :value="null">Seç</option>
              <option v-for="i in 90" :value="i">{{i}} günde</option>
            </select>
            <p class="input-description-center">teslim ederim. Ücret</p>
            <select v-model="extraFastDelivery.basic.price"
                    class="extras-dropdown">
              <option :value="null">Fiyat</option>
              <option v-for="i in 1000" :value="i*10">+{{i*10}} TL</option>
            </select>
          </div>
        </div>
        <div class="extras-fast-inner" v-if="enable3Packages">
          <p class="custom-extras-title">STANDART PAKET</p>
          <div class="extras-additional">
            <p class="input-description-left">Sadece</p>
            <select v-model="extraFastDelivery.standard.duration"
                    class="extras-dropdown">
              <option :value="null">Seç</option>
              <option v-for="i in 90" :value="i">{{i}} günde</option>
            </select>

            <p class="input-description-center">teslim ederim. Ücret</p>
            <select v-model="extraFastDelivery.standard.price"
                    class="extras-dropdown">
              <option :value="null">Fiyat</option>
              <option v-for="i in 1000" :value="i*10">+{{i*10}} TL</option>
            </select>
          </div>
        </div>
        <div class="extras-fast-inner" v-if="enable3Packages">
          <p class="custom-extras-title">PREMIUM PAKET</p>
          <div class="extras-additional">
            <p class="input-description-left">Sadece</p>
            <select v-model="extraFastDelivery.premium.duration"
                    class="extras-dropdown">
              <option :value="null">Seç</option>
              <option v-for="i in 90" :value="i">{{i}} günde</option>
            </select>
            <p class="input-description-center">teslim ederim. Ücret</p>
            <select v-model="extraFastDelivery.premium.price"
                    class="extras-dropdown">
              <option :value="null">Fiyat</option>
              <option v-for="i in 1000" :value="i*10">+{{i*10}} TL</option>
            </select>
          </div>
        </div>
        <aside class="tooltiptext">
          <help-box header="Süper Hızlı Teslimat" title="" description="<ul style='margin-left:20px;list-style:disc'><li>Daha hızlı teslim süresi için fiyatlandırmanı oluştur.</li><li style='margin-top:20px'>Süper Hızlı Teslimat süresi, paketlerinde belirttiğin süreden daha kısa olmalıdır.</li></ul>"></help-box>
        </aside>
      </div>


      <!--extra words-->

      <div class="table-item-body extras" v-if="extraWordObject.show">
        <div class="extras-header">
          <div class="checkbox-box"
               @click="extraWords.selected = !extraWords.selected">
            <img v-if="extraWords.selected" class="checkbox-img"
                 :src="extraWords.selected ? 'https://gcdn.bionluk.com/site/icon/tansel_radio_tick_greed.svg' : ''"/>
          </div>
          <p class="extras-title">Ekstra Kelime</p>
        </div>
        <aside class="tooltiptext">
          <help-box header="Süper Hızlı Teslimat" title="" description="<ul style='margin-left:20px;list-style:disc'><li>Daha hızlı teslim süresi için fiyatlandırmanı oluştur.</li><li style='margin-top:20px'>Süper Hızlı Teslimat süresi, paketlerinde belirttiğin süreden daha kısa olmalıdır.</li></ul>"></help-box>
        </aside>
      </div>
      <!--extra word body -->
      <div class="table-item-body extras" style="flex-direction: column; align-items: flex-start" v-if="extraWordObject.show && extraWords.selected && categorySubId !== 61">
        <div class="extras-fast-inner">
          <p class="custom-extras-title">TEMEL PAKET</p>
          <div class="extras-additional">
            <p class="input-description-left">Her ekstra</p>
            <select v-model="extraWords.basic.word"
                    class="extras-dropdown">
              <option :value="null">Seç</option>
              <option v-for="word in extraWordValues" :value="word">{{word}} kelime</option>
            </select>
            <p class="input-description-center"> için </p>
            <select v-model="extraWords.basic.price"
                    class="extras-dropdown">
              <option :value="null">Fiyat seçin...</option>
              <option v-for="i in 1000" :value="i*10">+{{i*10}} TL</option>
            </select>
          </div>
        </div>
        <div class="extras-fast-inner" v-if="enable3Packages">
          <p class="custom-extras-title">STANDART PAKET</p>
          <div class="extras-additional">
            <p class="input-description-left">Her ekstra</p>
            <select v-model="extraWords.standard.word"
                    class="extras-dropdown">
              <option :value="null">Seç</option>
              <option v-for="word in extraWordValues" :value="word">{{word}} kelime</option>
            </select>

            <p class="input-description-center"> için </p>
            <select v-model="extraWords.standard.price"
                    class="extras-dropdown">
              <option :value="null">Fiyat seçin...</option>
              <option v-for="i in 1000" :value="i*10">+{{i*10}} TL</option>
            </select>
          </div>
        </div>
        <div class="extras-fast-inner" v-if="enable3Packages">
          <p class="custom-extras-title">PREMIUM PAKET</p>
          <div class="extras-additional">
            <p class="input-description-left">Her ekstra</p>
            <select v-model="extraWords.premium.word"
                    class="extras-dropdown">
              <option :value="null">Seç</option>
              <option v-for="word in extraWordValues" :value="word">{{word}} kelime</option>
            </select>
            <p class="input-description-center"> için </p>
            <select v-model="extraWords.premium.price"
                    class="extras-dropdown">
              <option :value="null">Fiyat seçin...</option>
              <option v-for="i in 1000" :value="i*10">+{{i*10}} TL</option>
            </select>
          </div>
        </div>
        <aside class="tooltiptext">
          <help-box header="Ekstra Kelime" title="" description="<ul style='margin-left:20px;list-style:disc'><li>Paketinde belirlediğin kelime sayısını aşan durumlar için TL/kelime fiyatını belirleyebilirsin.</li><li style='margin-top:20px'></li>Böylelikle ilan sayfana otomatik fiyat hesaplama aracı eklenmiş olacak.</ul>"></help-box>
        </aside>
      </div>


      <!--extras-->
      <div class="table-item-body extras" v-for="item in gigDefaultExtras" :key="'extras_' +item.gig_default_extra_id" @mouseover="setTooltipData(item)"  v-if="!relatedExtraItems.includes(item.gig_default_extra_id)">
        <div class="extras-header">
          <div class="checkbox-box"
               @click="handleCheckBoxData(null, item.gig_default_extra_id, 4)">
            <img v-if="extras['selected_' + item.gig_default_extra_id] && extras['selected_' + item.gig_default_extra_id].selected" class="checkbox-img"
                 :src="extras['selected_' + item.gig_default_extra_id] && extras['selected_' + item.gig_default_extra_id].selected ? 'https://gcdn.bionluk.com/site/icon/tansel_radio_tick_greed.svg' : ''"/>
          </div>
          <p class="extras-title">{{item.title}}</p>
        </div>



        <div class="extras-additional" v-if="extras['selected_' + item.gig_default_extra_id] && extras['selected_' + item.gig_default_extra_id].selected">
          <p class="input-description-left">Ekstra</p>
          <select v-model="extras['selected_' + item.gig_default_extra_id].price"
                  class="extras-dropdown">
            <option :value="null">Fiyat</option>
            <option v-for="i in 1000" :value="i*10">+{{i*10}} TL</option>
          </select>

          <template v-if="item.info && item.info.selectboxRangeStart && item.info.selectboxRangeEnd">
            <p class="input-description-center"> için ekstra</p>
            <select v-model="extras['selected_' + item.gig_default_extra_id].value"
                    class="extras-dropdown">
              <option :value="null">Seç</option>
              <option  v-for="i in (item.info.selectboxRangeEnd/item.info.selectboxRangeInterval)" :value="i * item.info.selectboxRangeInterval">{{i * item.info.selectboxRangeInterval }}{{item.info.selectboxHelpText}}</option>
            </select>
          </template>
          <template v-else>
            <p class="input-description-center">ve ek olarak</p>
            <select v-model="extras['selected_' + item.gig_default_extra_id].duration"
                    class="extras-dropdown">
              <option :value="null">Teslim Süresi</option>
              <option :value="0" >+0 gün</option>
              <option v-for="i in 90" :value="i">+{{i}} gün</option>
            </select>
          </template>
        </div>

        <aside class="tooltiptext">
          <help-box :header="item.title" title="" :description="tooltipData.description"></help-box>
        </aside>
      </div>


      <!--extra revision-->
      <div class="table-item-body extras">
        <div class="extras-header">
          <div class="checkbox-box"
               @click="handleCheckBoxData(null, 'revision', 4)">
            <img v-if="extras['selected_revision'] && extras['selected_revision'].selected" class="checkbox-img"
                 :src="extras['selected_revision'] && extras['selected_revision'].selected ? 'https://gcdn.bionluk.com/site/icon/tansel_radio_tick_greed.svg' : ''"/>
          </div>
          <p class="extras-title">Ekstra Revizyon</p>
        </div>
        <!--extra revision body-->
        <div class="extras-additional" v-if="extras['selected_revision'] && extras['selected_revision'].selected">
          <p class="input-description-left">Ekstra</p>
          <select v-model="extras['selected_revision'].price"
                  class="extras-dropdown">
            <option :value="null">Fiyat</option>
            <option v-for="i in 1000" :value="i*10">+{{i*10}} TL</option>
          </select>

          <p class="input-description-center">ve ek olarak</p>
          <select v-model="extras['selected_revision'].duration"
                  class="extras-dropdown">
            <option :value="null">Teslim Süresi</option>
            <option :value="0" >+0 gün</option>
            <option v-for="i in 90" :value="i">+{{i}} gün</option>
          </select>
        </div>

        <aside class="tooltiptext">
          <help-box header="Ekstra Revizyon" title="" description="Paket içeriğinde belirttiğin revizyon sayılarından daha fazla sayıda revizyon almak isteyen müşterilerin için ekstra revizyon fiyatı belirleyebilirsin"></help-box>
        </aside>
      </div>

      <!--custom extra gig-->
      <div class="table-item-body custom-extras" v-for="(gig, index) in customExtraGig" :key="'extraGig_' + index">
        <div class="extras-header">
          <div class="checkbox-box"
               style="margin-right: 10px;"
               @click="gig.selected = !gig.selected">
            <img v-if="gig.selected" class="checkbox-img"
                 :src="gig.selected ? 'https://gcdn.bionluk.com/site/icon/tansel_radio_tick_greed.svg' : ''"/>
          </div>
          <p class="custom-extras-title">Başlık</p>
          <input type="text" style="height: 16px; border:1px solid #ccc;!important;" v-model="gig.title" placeholder="Ekstra Servis Başlığı"/>

        </div>

        <div style="display: flex;  margin-left: 42px;">
          <p class="custom-extras-title">Açıklama</p>
          <input type="text" style="height: 16px; width: 400px" v-model="gig.description" placeholder="Ekstra olarak şunu da yaparım..."/>
        </div>


        <div class="custom-extras-additional">
          <p class="custom-extras-title">Ekstra Tutar</p>
          <select v-model="gig.price"
                  class="extras-dropdown">
            <option :value="null">Fiyat</option>
            <option v-for="i in 1000" :value="i*10">{{i*10}} TL</option>
          </select>

          <p class="input-description-center">ve ek olarak</p>
          <select v-model="gig.duration"
                  class="extras-dropdown">
            <option :value="null">Teslim Süresi</option>
            <option v-for="i in 90" :value="i-1">+{{i-1}} gün</option>
          </select>
        </div>
        <aside class="tooltiptext">
          <help-box header="Sana Özel Ekstra Ekle" title="" description="Yukarıda bulunmayan ekstra seçenekler için, kendi hizmetine özel ekstra oluştrabilirsin."></help-box>
        </aside>
      </div>

      <!--add button-->
      <div class="table-item-body" style="padding-top: 20px; padding-bottom: 20px;" v-if="customExtraGig !== undefined && customExtraGig.length !== undefined  && customExtraGig.length < 3">
        <p class="add-extragig-button" @click="addExtraGig()">+ Ekstra Ekle</p>
        <aside class="tooltiptext">
          <help-box header="Sana Özel Ekstra Ekle" title="" description="Yukarıda bulunmayan ekstra seçenekler için, kendi hizmetine özel ekstra oluştrabilirsin."></help-box>
        </aside>
      </div>
    </div>


  </div>

</template>

<script>

  import helpBox from './HelpBox'
  import CustomSwitch from './CustomSwitch'
  import _isEmpty from 'lodash/isEmpty'
  import _isArray from 'lodash/isArray'

  export default {
    name: 'PricingComponent',
    components: {
      helpBox,
      CustomSwitch
    },
    props: {

      gigPackageItems: {
        type: Array,
        default: [],
      },

      gigDefaultExtras: {
        type: Array,
        default: [],
      },

      extraWordObject: {
        type: Object,
        default: {},
      },

      packages: {
        type: Object,
        default: {},
      },

      categorySubId: {
        type: Number,
        default: null,
      },

    },
    data() {
      return {
        enable3Packages: false,
        tooltipData: {},
        pricingAnswer: {},
        basic: {},
        standard: {},
        premium: {},
        extras: {},
        customExtraGig: [],
        extraWordValues: [],
        extraWords: {},
        extraFastDelivery: {
          selected: false,
          basic: {
            price: null,
            duration: null
          },
          standard: {
            price: null,
            duration: null
          },
          premium: {
            price: null,
            duration: null
          }
        },

        // voiceover
        voiceoverOptions: {
          includes: null,
          revisions: null,
          price: null,
          additionalWordCount: null,
          additionalWordPrice: null,
          duration: null
        },

        relatedExtraItems: [],
        relatedExtraItemsResetCompleted:false,
      }
    },

    methods: {

      setTooltipData(item) {
        this.tooltipData = {
          description: item.description
        }
      },
      handleCheckBoxData(e, id, type, item) {
        let data;
        if (e && e.target) {
          data = e.target.value;
        } else {
          data = e;
        }


        if (type === 1) {
          const obj = {...this.basic};
          obj['selected_' + id] = data;
          this.basic = {...obj};
        } else if (type === 2) {
          const obj = {...this.standard};
          obj['selected_' + id] = data;
          this.standard = {...obj};
        } else if (type === 3) {
          const obj = {...this.premium};
          obj['selected_' + id] = data;
          this.premium = {...obj};
        } else if (type === 4) { //extras
          const obj = {...this.extras};
          if (!obj['selected_' + id]) {
            obj['selected_' + id] = {};
            obj['selected_' + id].price = null;
            obj['selected_' + id].duration = null;
            obj['selected_' + id].selected = true;
            obj['selected_' + id].value = null;
          } else {
            obj['selected_' + id].selected = !obj['selected_' + id].selected;
          }
          this.extras = {...obj};

        }
        const checkIfPackageItem = type === 1 || type === 2 || type === 3;

        if(checkIfPackageItem) this.mutateRelatedId(item);
      },

      handleSelectBoxData(data, item) {
        this.mutateRelatedId(item);
      },

      addExtraGig() {
        if (this.customExtraGig !== undefined && this.customExtraGig.length < 5) {
          const obj = {
            selected: false,
            title: '',
            description: '',
            price: null,
            duration: null
          };
          this.customExtraGig.push(obj);
        }
      },

      setDefaultData() {
        this.enable3Packages = false;
        this.extras = {};
        this.customExtraGig = [];

        this.extraFastDelivery = {
          selected: false,
          basic: {
            price: null,
            duration: null
          },
          standard: {
            price: null,
            duration: null
          },
          premium: {
            price: null,
            duration: null
          }
        };

        this.extraWords = {
          selected: false,
          basic: {
            price: null,
            word: null
          },
          standard: {
            price: null,
            word: null
          },
          premium: {
            price: null,
            word: null
          }
        };

        this.basic = {};
        this.basic.selected_title = null;
        this.basic.selected_description = null;
        this.basic.selected_duration = 0;
        this.basic.selected_revisions = null;
        this.basic.selected_pricing = 0;

        this.standard = {};
        this.standard.selected_title = null;
        this.standard.selected_description = null;
        this.standard.selected_duration = 0;
        this.standard.selected_revisions = null;
        this.standard.selected_pricing = 0;

        this.premium = {};
        this.premium.selected_title = null;
        this.premium.selected_description = null;
        this.premium.selected_duration = 0;
        this.premium.selected_revisions = null;
        this.premium.selected_pricing = 0;
      },

      setDefaultPackageItems() {
        this.gigPackageItems.forEach(g => {
          this.basic['selected_' + g.gig_package_item_id] = null;
          this.standard['selected_' + g.gig_package_item_id] = null;
          this.premium['selected_' + g.gig_package_item_id] = null;
        });
      },

      setDefaultExtras() {
        this.gigDefaultExtras.forEach(g => {
          this.extras['selected_' + g.gig_default_extra_id] = {
            selected: false,
            price: null,
            duration: null,
            value:null
          };
          this.extras.selected_revision = {
            selected: false,
            price: null,
            duration: null
          }
        });

        this.mutateExtraWords();

      },

      setPackages() {
        const {basic, standard, premium, extras, packageCount, related_extra_items, voiceoverOptions} = this.packages;

        this.enable3Packages = packageCount === 3;
        this.relatedExtraItems = _isArray(related_extra_items) ? related_extra_items : []

        this.setPackage(basic, 'basic');
        this.setPackage(standard, 'standard');
        this.setPackage(premium, 'premium');

        if (!_isEmpty(extras)) {
          if (extras.defaultExtras !== undefined && extras.customExtras.length > 0) {
            this.customExtraGig = extras.customExtras;
          }
          if (extras.defaultExtras !== undefined && extras.defaultExtras.length > 0) {
            extras.defaultExtras.forEach(e => {
              this.extras['selected_' + e.gig_default_extra_id] = {
                selected: e.selected,
                price: e.price,
                duration: e.duration,
                value:e.value
              };
            });
          }
          if(!_isEmpty(extras.revisionExtra)) {
            this.extras.selected_revision = extras.revisionExtra;
          }
        }

        this.voiceoverOptions = {...voiceoverOptions};
      },

      setPackage(packageValue, packageName) {
        for (let key in packageValue) {
          const value = packageValue[key];

          if (typeof value === 'number' || typeof value === 'string') {
            this[packageName] = {...this[packageName]};
            this[packageName]['selected_' + key] = value;
          } else if (key === 'extra_fast_delivery' && !_isEmpty(value)) {
            this.extraFastDelivery.selected = true;
            this.extraFastDelivery[packageName] = {
              price: value.price,
              duration: value.duration
            }
          } else if (key === 'items' && value !== null &&  value !== undefined && value.length) {
            value.forEach(v => {
              this[packageName]['selected_' + v.gig_package_item_id] = v.value;
            });
          } else if (key === 'extra_words' && !_isEmpty(value)) {
            this.extraWords.selected = true;
            this.extraWords[packageName] = {
              price: value.price,
              word: value.word
            }
          }
        }

      },

      mutateExtraWords() {
        this.extraWordValues = [];
        for(let i = this.extraWordObject.min; i<= this.extraWordObject.max; i = i + this.extraWordObject.interval) {
          this.extraWordValues.push(i);
        }
      },

      mutateRelatedId(item, reset = false) {
        let data;
        if (reset) {
          this.relatedExtraItems = [];
          for(const key in this.basic) {
            const id = key.split('_')[1];
            if(!isNaN(id)) {
              const basic = this.basic['selected_' + id];
              const standard = this.standard['selected_' + id];
              const premium = this.premium['selected_' + id];
              const packageBool =  (this.enable3Packages && !!basic && !!standard && !!premium) || (!this.enable3Packages && !!basic);
              if(packageBool) {
                const foundPackage = this.gigPackageItems.find(({ gig_package_item_id }) => gig_package_item_id === Number(id))
                if(foundPackage){
                  this.relatedExtraItems.push(foundPackage.related_default_extra_id);
                }
              }
            }
          }
          this.relatedExtraItemsResetCompleted = true;
        } else {
          const basic = this.basic['selected_' + item.gig_package_item_id];
          const standard = this.standard['selected_' + item.gig_package_item_id];
          const premium = this.premium['selected_' + item.gig_package_item_id];
          if(this.enable3Packages) {
            data = !!basic && !!standard && !!premium;
          } else {
            data = !!basic;
          }
          if (!data && this.relatedExtraItems.includes(item.related_default_extra_id)) {
            this.relatedExtraItems = this.relatedExtraItems.filter(extraItem => extraItem !== item.related_default_extra_id) || []
          }

          if (data && !this.relatedExtraItems.includes(item.related_default_extra_id)) {
            this.relatedExtraItems.push(item.related_default_extra_id)
          }
        }
      }
    },
    watch: {
      gigPackageItems: function (newVal) {
        if(newVal) this.setDefaultPackageItems();
      },

      gigDefaultExtras: function (newVal) {
        if(newVal) this.setDefaultExtras();
      },

      packages: function (newVal) {
        if(newVal) this.setPackages();
      },

      relatedExtraItems(newVal) {
        if (newVal) {
          Array.from(newVal).forEach( r => {
            if(this.extras.hasOwnProperty('selected_' + r) && this.relatedExtraItemsResetCompleted) {
              this.extras['selected_' + r].selected = false;
            }
          });
        }
      },

      enable3Packages(newVal) {
        this.mutateRelatedId(null, true);
      }
    },


    created() {
      this.setDefaultData();
      this.setDefaultPackageItems();
      this.setDefaultExtras();
      if (!_isEmpty(this.packages)) this.setPackages();

      this.EventBus.$on('pricingRequestData', payload => {
        if (payload === 'sendInfo' || payload === 'sendInfo') {
          this.EventBus.$emit('pricingResponseData', {payloadType: payload, basic: this.basic, standard: this.standard, premium: this.premium, extras: this.extras, customExtraGig: this.customExtraGig, extraFastDelivery: this.extraFastDelivery, enable3Packages: this.enable3Packages, extraWords: this.extraWords, voiceoverOptions: this.voiceoverOptions});
        } else if (payload === 'reset') {
          this.setDefaultData();
        }
      });
    },

    beforeDestroy() {
      this.EventBus.$off('pricingRequestData')
    }

  }

</script>

<style scoped lang="scss">


  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-weight: 300;
    opacity: 0.8;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    font-weight: 300;
    opacity: 0.8;
  }

  :-ms-input-placeholder { /* IE 10+ */
    font-weight: 300;
    opacity: 0.8;
  }

  :-moz-placeholder { /* Firefox 18- */
    font-weight: 300;
    opacity: 0.8;
  }

  select {
    color: #2d3640 !important;
  }

  textarea {
    border: none !important;
  }

  input {

    border: none !important;
    height: 50px;
    color: #2d3640;
  }

  .pricing-body {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
  }

  .table-container {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #e1e1e1;
    background-color: #F4F5F7;
  }

  .table-row-header {
    display: flex;
    align-items: center;
    text-align: left;

    width: 170px;
    height: 48px;

    border-bottom: 1px solid #bfc8d2;

    font-weight: 300;
    font-size: 14px;
    .span {
      padding-left: 12px;
    }

  }

  .table-col-header {
    display: flex;
    align-items: center;

    width: 220px;
    height: 50px;
    text-align: left;
    border: 1px solid #bfc8d2;
    border-right: none;
    border-top: none;
    font-weight: 600;
    color: #2d3640;
    font-size: 15px;

  }

  .table-item-body {
    display: flex;
    align-items: center;
    position: relative;
    transform: scale(1);

    &:hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }

  .table-item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 219px;
    background-color: #fff;
    border: 1px solid #bfc8d2;
    border-right: none;
    border-top: none;
  }

  .should-left {
    padding-left: 170px;
  }

  .dynamic {
    max-height: 180px;
    width: 800px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
  }

  .tooltiptext {
    display: block;
    visibility: hidden;
    position: absolute;
    width: 200px;
    min-height: 100%;
    background-color: #2d3640;
    color: #fff;
    text-align: center;
    padding: 5px 3px;
    border-radius: 6px;
    z-index: 1;
    left: -240px;
    top: 0;
    font-size: 14px;
  }

  .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 20px;
    left: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent transparent #2d3640;
  }

  .pricing-input {
    width: 200px;
    height: 29px;
    &:hover {
      background-color: #ecf5ff;
    }
  }

  .checkbox-body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 199px;
    height: 48px;

    &:hover {
      background-color: #ecf5ff;
    }
  }

  .checkbox-box {
    position: relative;
    border: 1px solid #bfc8d2;
    height: 20px;
    width: 20px;
    background-color: #fff;
    margin-right: 10px;
    .checkbox-img {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      height: 20px;
      width: 20px;
    }
  }

  .pricing-dropdown {
    -webkit-appearance: none;
    background: url(https://gcdn.bionluk.com/site/icon/aws/icon_alt_ok_1.svg) no-repeat right;
    background-position-x: 160px;
    -moz-appearance: none;
    min-width: 178px;
    width: 178px;
    height: 49px;
    appearance: none;
    border-radius: 0px;
    font-family: 'sofia-pro';
    background-color: #fefdfd;
    color: #5e6b79;

    border: none;
    font-size: 14px;
    padding: 9px 10px 11px;
    &:hover {
      color: #fd4056;
      background-color: #ecf5ff;

    }
  }

  /*extras*/

  .extras {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #bfc8d2;
    padding-right: 10px;
  }

  .custom-extras {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #bfc8d2;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .extras-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    padding-left: 12px;
  }

  .extras-title {
    margin-left: 10px;
    font-weight: 400;
    color: #2d3640;
    font-size: 15px;
  }

  .custom-extras-title {
    font-weight: 300;
    font-size: 14px;
    width: 150px;
  }

  .extras-additional {
    display: flex;
    align-items: center;
  }

  .extras-dropdown {
    background: url(https://gcdn.bionluk.com/site/icon/aws/icon_alt_ok_1.svg) no-repeat right;
    background-position-x: 115px;
    -moz-appearance: none;
    min-width: 130px;
    width: 130px;
    height: 35px;
    appearance: none;
    border-radius: 0px;
    font-family: 'sofia-pro';
    background-color: #fefdfd;
    color: #5e6b79;

    border: none;
    font-size: 14px;
    padding: 7px 8px 9px;
    &:hover {
      color: #fd4056;
      background-color: #ecf5ff;

    }
  }

  .input-description-left {
    margin-right: 8px;
    font-weight: 300;
    font-size: 14px;
  }

  .add-extragig-button {

    font-size: 15px;
    cursor: pointer;
    color: #3894d6;
    margin-left: 12px;
  }

  .custom-extras-additional {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 42px;
  }

  .service-type-title {
    font-weight: 600;
    color: #2d3640;
    font-size: 16px;
    margin-right: 15px;

  }

  .extras-fast-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 30px);
    height: 60px;
    padding-left: 30px;
  }

  .packagesOpacity {
    height: 100%;
    background: rgba(244, 245, 247, 0.85);
    width: 440px;
    z-index: 1;
    right: 0;
    top:0;
    position: absolute;
  }

  .enable3Packages-body {
    position: absolute;
    top: -50px;
    right: 0;
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .enable3Packages {
    margin-right: 5px;
    margin-left: 10px;
  }


  /*voiceover*/

  .voiceover {
    padding: 0px 20px 20px 17px;
  }

  .voiceover-label {
    width: 200px;
    font-weight: 300;
    font-size: 14px;
  }

  .voiceover-item-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .input-description-right {
    font-weight: 300;
    font-size: 14px;
    margin-left: 8px;
  }

  .input-description-center {
    font-weight: 300;
    font-size: 14px;
    margin-left: 15px;
    margin-right: 8px;
  }

  .small-input {
    min-width: 114px;
    width: 114px;
    height: 19px;
    border-radius: 0px;
    background-color: #fefdfd;
    color: #5e6b79;
    border: none;
    font-size: 14px;
    padding: 7px 8px 9px;
  }

  .voiceover-seperator {
    margin-top: 20px;
    border-bottom: 1px solid #bfc8d2;
  }
</style>
